import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import * as S from './styled'
import { graphql, useStaticQuery } from 'gatsby'
import arrayPagination from '../../../utils/arrayPaginate'
import BlogCard from '../BlogCard'
import BlogCardThin from '../BlogCardThin'
import Pagination from '../../../utils/Pagination'
import PinkLine from '../../shared/PinkLine'
import { useLocation } from '@reach/router'
import { listCategories } from '../../../utils/listCategories'
import { Button } from "@dasa-health/alma-react"
import { Close } from '@dasa-health/alma-icons'
import getSlugByCountry from '../../../utils/getSlugByCountry'
import SwiperCore from "swiper";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css'
import 'swiper/swiper-bundle.min.css'
import 'swiper/components/pagination/pagination.min.css'

SwiperCore.use([Pagination]);

const BlogHome = ({ headerInfo, data, highlightedPosts, regularPosts }) => {
  const results = useStaticQuery(
    graphql`
      query {
        allWordpressWpClinicalStaffPage(sort: { fields: acf___name, order: ASC }) {
          nodes {
            wordpress_id
            slug
            acf {
              council_number
              title_gender
              name
              description
              specialization_area
              usuario
              photo_medic {
                localFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
        wordpressWpUsers(wordpress_id: {eq: 1}) {
          id
          name
          wordpress_id
        }
        wordpressWpLogo {
          id
          url {
            localFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        allWordpressCategory(sort: {order: ASC, fields: name}) {
          edges {
            node {
              wordpress_id
              name
            }
          }
        }        
      }
  `)

  const doctors = results.allWordpressWpClinicalStaffPage.nodes || false
  const authorDasa = results.wordpressWpUsers || false
  const logoDasa = results.wordpressWpLogo || false
  const allPosts = [...regularPosts, ...highlightedPosts];
  const blogData = data.wordpressWpGatsbyPage.acf
  const blogPageSlug = getSlugByCountry('blog')

  const categories = results.allWordpressCategory ? results.allWordpressCategory.edges : false
  const [categoriesSelected, setCategoriesSelected] = useState([blogData.blog_all_categories_filter_title])
  
  const regularScroll = useRef()
  const filteredScroll = useRef()
  const [firstRender, setFirstRender] = useState(true)

  const [searchParam] = useState(new URLSearchParams(useLocation().search).get("search") || '')
  const [searchFilter] = useState(searchParam)

  const [categoryParam] = useState(new URLSearchParams(useLocation().search).get("category"))

  const MAX_CARDS_VISIBLE = 10
  const [cardsVisible, setCardsVisible] = useState(false);
  const [relatedSearchVisible, setRelatedSearchVisible] = useState(false)
  
  const [regularPostsList] = useState(arrayPagination(regularPosts, MAX_CARDS_VISIBLE))
  const [regularPage, setRegularPage] = useState(0)
  const [countPages, setCountPages] = useState(regularPostsList.length)

  const [relatedSearchData, setRelatedSearchData] = useState([])
  const [filteredSearchData, setFilteredSearchData] = useState([])
  const [searchPage, setSearchPage] = useState(0)
  const [countSearchPages, setCountSearchPages] = useState(filteredSearchData.length)

  const allCategories = blogData.blog_all_categories_filter_title

  const [serchResultVisible, setSearchResultVisible] = useState(false)
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if(searchParam === '') {
      setSearchResultVisible(false)
    } else {
      setSearchResultVisible(true)
    }
  })

  const formatString = (string) => {
    return string.toLowerCase().normalize('NFD').replace(/\p{Mn}/gu, "") || ''
  }

  const filterSearchPosts = (search) => {
    return allPosts.filter(
      (post) => formatString(post.node.title).includes(formatString(search))
    )
  }

  useEffect(() => {
    if(firstRender) return setFirstRender(false)
    if(typeof window !== `undefined` && typeof regularScroll !== `undefined`) {
      window.scrollTo({
        top: (regularScroll.current.offsetTop - 150),
        behavior: 'smooth'
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regularPage])

  useEffect(() => {
    if(firstRender) return setFirstRender(false)
    if(typeof window !== `undefined` && typeof filteredScroll !== `undefined`) {
      window.scrollTo({
        top: (filteredScroll.current.offsetTop - 150),
        behavior: 'smooth'
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchPage])

  useEffect(() => {
    setCountPages(regularPostsList.length)
  }, [regularPostsList])

  useEffect(() => {
    setCountSearchPages(filteredSearchData.length)
  }, [filteredSearchData])

  const handleChangeSearch = (event) => {
    if(event.target.value.length > 2) {
      const result = filterSearchPosts(event.target.value)
      setRelatedSearchData(result)
      setRelatedSearchVisible(true)
    }

    if(!event.target.value) {
      setRelatedSearchVisible(false)
      setRelatedSearchData([])
    }
  }

  useEffect(() => {
    if(firstRender && searchParam !== '') {
      const result = filterSearchPosts(searchParam)
      setFilteredSearchData(arrayPagination(result, MAX_CARDS_VISIBLE))
    }
    
    if(!searchParam || searchParam === '') {
      setCardsVisible(true)
      setFilteredSearchData([])
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function removeItemFilterCategory(category) {
    let newList = []

    categoriesSelected.map(item => {
      if(item !== category) {
        return newList = newList.concat([item])
      }

      return false
    })

    return setCategoriesSelected(newList)
  }

  function addItemFilterCategory(category) {
    // remove o "todas as categorias"
    if (categoriesSelected.includes(allCategories)) setCategoriesSelected(categoriesSelected.shift())

    const categoriesMerged = categoriesSelected.concat([category])
    setCategoriesSelected(categoriesMerged)
  }

  function handleFilterCategory(category, event) {
    event.preventDefault()

    if (category === allCategories) {
      setCardsVisible(true)
      setFilteredSearchData([])
      return setCategoriesSelected([category])
    } 

    if(categoriesSelected.includes(category)){
      removeItemFilterCategory(category)
    } else {
      addItemFilterCategory(category)
    }    
  }

  const orderResultsSearchPosts = (results) => {
    return results.sort(function compare(a, b) {
      let date_a = new Date(a.node.unformattedDate)
      let date_b = new Date(b.node.unformattedDate)

      if(date_a < date_b) return 1;
      if(date_a > date_b) return -1;
      return 0;
    })
  }

  useEffect(() => {
    if(categoriesSelected.length === 0) {
      setCardsVisible(true)
      setFilteredSearchData([])
      return setCategoriesSelected([allCategories])
    }

    let filter = []

    if(categoriesSelected.length > 0) {
      categoriesSelected.map(category => {

        if(category !== allCategories && category !== '') {
          const filteredPosts = allPosts.filter(
            (post) => formatString(post.node.categories[0].name).includes(formatString(category))
          )

          filter = orderResultsSearchPosts(filter.concat(filteredPosts))

          setFilteredSearchData(arrayPagination(filter, MAX_CARDS_VISIBLE))
          setSearchPage(0)
          setFirstRender(false)
          setCardsVisible(false)
        }

        return true
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [categoriesSelected])

  useEffect(() => {
    if(categoryParam) setCategoriesSelected([categoryParam])
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [])

  return (
    <>
      <S.BlogHomeWrapper>
        <S.BlogTitleAndSearch>
          <S.BlogTitle>
            {headerInfo.subtitle}
          </S.BlogTitle>

          {headerInfo.demonstrative_text && (
            <S.BlogSubtitle>
              {headerInfo.demonstrative_text}
            </S.BlogSubtitle>
          )}

          <S.SearchWrapper>
            <S.SearchForm>
              <S.SearchIconLink to={`/${blogPageSlug}/`}>
                <S.SearchIcon />
              </S.SearchIconLink>

              <S.SearchInput
                name='search'
                defaultValue={searchFilter || ""}
                onChange={handleChangeSearch}
                autoComplete="off"
              />
            </S.SearchForm>

            {relatedSearchVisible && (
              <S.SearchResults>
                {relatedSearchData.slice(0, 3).map(({ node }) => (
                  <S.ResultTitleLink to={`/${blogPageSlug}/${node.slug}/`}>
                    <S.ResultItem key={node}>
                      <S.ResultCategory>
                        {listCategories(node.categories)}
                      </S.ResultCategory>

                      <S.ResultTitle>
                        {node.title}
                      </S.ResultTitle>

                      <S.ResultDate>
                        {node.date}
                      </S.ResultDate>
                    </S.ResultItem>
                  </S.ResultTitleLink>
                ))}
              </S.SearchResults>
            )}
          </S.SearchWrapper>
        </S.BlogTitleAndSearch>

        <S.ResultSearchContent 
          className={serchResultVisible ? 'visible' : ''}
        >
          <S.TitleSearchWrapper>  
            <S.LinkBack href={`/${blogPageSlug}/`}>
              <S.ArrowBack />
            </S.LinkBack>

            <S.TitleSearch>
              {blogData.blog_search_title} <span>{searchParam}</span>
            </S.TitleSearch>
          </S.TitleSearchWrapper>
        </S.ResultSearchContent>

        <S.CategoryContent 
          search={serchResultVisible}
        >
          <S.ListCategories search={searchParam}>
            <S.Category 
              className={`${categoriesSelected.includes(allCategories) ? "active" : ""} all`}
              key="category_1"
              onClick={(e) => handleFilterCategory(e.target.textContent, e)}
            >
              {blogData.blog_all_categories_filter_title}
            </S.Category>

            {categories.map(({ node }) => (
              node.wordpress_id !== 1 && (
                !categoriesSelected.includes(node.name)
                  ?
                    <S.Category 
                      key={node}
                      className={categoriesSelected.includes(node.name) ? "active" : ""}
                      onClick={(e) => handleFilterCategory(e.target.textContent, e)}
                    >
                      {node.name} 
                    </S.Category> 
                  :
                    <S.Category 
                      key={node}
                      className={categoriesSelected.includes(node.name) ? "active" : ""}
                    >
                      {node.name} 
  
                      <Close 
                        onClick={(e) => handleFilterCategory(node.name, e)}
                      />
                    </S.Category>
                )
            ))}
          </S.ListCategories>
        </S.CategoryContent>

        <S.CategoryContentMobile
          hidden={searchParam === '' ? true : false}
        >
          <S.ListCategoriesMobile>
            <S.CarouselCategoriesContainer>
                  <Swiper
                    slidesPerView={3}
                    spaceBetween={8}
                  >
                    <SwiperSlide>
                      <S.Category 
                        className={`${categoriesSelected.includes(allCategories) ? "active" : ""} all`}
                        key="category_1"
                        onClick={(e) => handleFilterCategory(e.target.textContent, e)}
                      >
                        {blogData.blog_all_categories_filter_title}
                      </S.Category>
                    </SwiperSlide>

                    {categories.map(({ node }) => (
                      node.wordpress_id !== 1 && (
                        <SwiperSlide>
                          {!categoriesSelected.includes(node.name)
                          ?
                            <S.Category 
                              key={node}
                              className={categoriesSelected.includes(node.name) ? "active" : ""}
                              onClick={(e) => handleFilterCategory(e.target.textContent, e)}
                            >
                              {node.name} 
                            </S.Category>
                          :
                            <S.Category 
                              key={node}
                              className={categoriesSelected.includes(node.name) ? "active" : ""}
                            >
                              {node.name} 

                                <Close onClick={(e) => handleFilterCategory(node.name, e)} />
                            </S.Category>
                          }
                        </SwiperSlide>
                      )
                    ))}
                  </Swiper>
            </S.CarouselCategoriesContainer>
          </S.ListCategoriesMobile>
        </S.CategoryContentMobile>

        {cardsVisible && (
          <>
            <S.HighlightPosts>
              {highlightedPosts.map(({ node }) => (
                <BlogCard 
                  post={node} 
                  key={node.slug}
                  authorList={doctors}
                  authorDasa={authorDasa}
                  logoDasa={logoDasa}
                  setCategories={setCategoriesSelected}
                />
              ))}
            </S.HighlightPosts>

            <S.TitleRecentPosts>
              {headerInfo.complementary_text}
            </S.TitleRecentPosts>

            <S.RegularPosts ref={regularScroll}>
              {regularPostsList[regularPage].map(({ node }) => (
                <BlogCardThin 
                  post={node}
                  key={node.slug}
                  authorList={doctors}
                  authorDasa={authorDasa}
                  logoDasa={logoDasa}
                  setCategories={setCategoriesSelected}
                />
              ))}
            </S.RegularPosts>

            <Pagination 
              currentPage={regularPage}
              totalPages={countPages}
              setPage={setRegularPage}
              color="blue"
            />
          </>
        )}

        {!cardsVisible && !firstRender && (
          <>
            {filteredSearchData.length > 0
            ?
              <S.FilteredSearch ref={filteredScroll}>
                {filteredSearchData[searchPage].map(({ node }) => (
                  <BlogCardThin 
                    post={node}
                    key={node.slug}
                    authorList={doctors}
                    authorDasa={authorDasa}
                    logoDasa={logoDasa}
                  /> 
                ))}
              </S.FilteredSearch>
            :
              <S.NoResultsContent>
                <S.NoResults>
                  {blogData.blog_no_results_title}
                </S.NoResults>

                <S.LinkButton href={`/${blogPageSlug}/`}>
                  <Button
                  >
                    {blogData.blog_back_button_title}
                  </Button>
                </S.LinkButton>
              </S.NoResultsContent>
            }

            {filteredSearchData.length > 0 && (
              <Pagination 
                currentPage={searchPage}
                totalPages={countSearchPages}
                setPage={setSearchPage}
                color="blue"
              />
            )}
          </>
        )}
      </S.BlogHomeWrapper>
      <PinkLine />
    </>
  )
}

BlogHome.propTypes = {
  regularPosts: PropTypes.array,
  highlightedPosts: PropTypes.array,
  page: PropTypes.number,
  totalPages: PropTypes.number,
}

export default BlogHome
