import styled from 'styled-components'
import media from 'styled-media-query'
import Img from 'gatsby-image'
import { alvTokens } from '@dasa-health/alma-tokens'
import { ChevronLeft, ChevronRight } from '@dasa-health/alma-icons'
import { Link } from 'gatsby'

export const ContainerSingleClinicalStaff = styled.div`
  position: relative;
`

export const ClinicalStaffContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  row-gap: 2.4rem;
  grid-template-areas:
		"img specialty"
		"img doctorName"
		"img council"
		"img description"
		"img button"
  ;
  padding: 4.8rem 0 8rem;

  ${media.lessThan('medium')`
    padding: 0 0 .8rem;
    grid-template-columns: 1fr;
    grid-template-areas:
      "specialty"
      "doctorName"
      "img"
      "council"
      "description"
      "button"
    ;
  `}
`

export const Image = styled(Img)`
  grid-area: img;
  width: 31.1rem;
  height: 40.4rem;
  border-radius: 8px;
  margin-right: 2.4rem;

  ${media.lessThan('medium')`
    width: calc(100vw - 3.2rem);
    height: auto;
    min-height: 36.4rem;
  `}
`

export const Name = styled('p')`
  grid-area: doctorName;
  color: var(--brand-dasa);
  font-size: var(--fontTitle);
  font-weight: 700;
  max-width: 53.4rem;
  letter-spacing: -1px;
  height: fit-content;

  ${media.lessThan('medium')`
    max-width: calc(100vw - 3.2rem);
    font-size: var(--font20);
  `}
`

export const Council = styled.div`
  grid-area: council;
`

export const CouncilNumber = styled('p')`
  width: fit-content;  
  font-size: 12px;
  font-weight: 700;
  line-height: 133%;
  padding: .4rem .8rem;
  color: ${alvTokens.ColorPaletteGray30};
  background: rgba(22, 22, 22, 0.08);
  border-radius: 24px;
  height: fit-content;
`

export const SpecialtyArea = styled('p')`
  grid-area: specialty;
  font-size: 12px;
  height: fit-content;
  font-weight: 700;
  line-height: 133%;
  letter-spacing: 1.5px;
  color: var(--pink);
  text-transform: uppercase;
`

export const ClinicalStaffDescription = styled.div`
  grid-area: description;
  max-width: 53.4rem;

  ${media.lessThan('medium')`
    max-width: calc(100vw - 3.2rem);
  `}

  p, li {
    font-size: var(--font16);
    color: ${alvTokens.ColorPaletteGray70};
    line-height: 150%;

    ${media.lessThan('medium')`
      font-size: var(--fontDefault);
    `}
  }

  ul {
    list-style: initial;
    padding-inline-start: 1.6rem;

    li::marker {
      font-size: 10px;
    }
  }
`

export const ImgMedical = styled.div`
  position: absolute;
  bottom: -15.3rem;
  right: 0;

  .gatsby-image-wrapper {
    width: 332px;
    height: 100%;
  }

  ${media.lessThan('medium')`
      display: none;
  `}
`

export const ImgMedicalNoPub = styled.div`
  position: absolute;
  bottom: -.2rem;
  right: 0;
  
  .gatsby-image-wrapper {
    width: 332px;
    height: 100%;
  }

  ${media.lessThan('medium')`
      display: none;
  `}
`

export const ButtonLink = styled.a`
  text-decoration: none;
`

export const Button = styled.div`
  display: flex;

  p {
    font-size: var(--font16);
  }
`

export const PublicationsContainer = styled.div`
  background-color: ${props => props.theme.colors.brand.primary[10]};
`

export const PublicationsContent = styled.div`
  max-width: 1094px;
  margin: 0 auto;
  padding-bottom: 3.2rem;

  ${media.lessThan('medium')`
    padding: 0 1.6rem 2.4rem;
  `}
`

export const PublicationsTitle = styled.h2`
  color: var(--neutral00);
  font-size: var(--font20);
  line-height: 120%;
  font-weight: 700;
  padding-top: 7.2rem;
  
  ${media.lessThan('medium')`

    padding-top: 3.2rem;
  `}
`

export const PublicationsList = styled.div`
  margin-top: 5.5rem;
  display: flex;
  flex-wrap: wrap;
  gap: 2.4rem;

  ${media.lessThan('medium')`
    margin-top: 3.2rem;
  `}
`

export const PublicationCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 50.3rem;
  height: 28.8rem;
  background: ${alvTokens.ColorNeutral10};
  border-radius: 4px;
  padding: 1.6rem;

  ${media.lessThan('medium')`
    flex-direction: column;
    justify-content: space-evenly;
    width: calc(100% - 3.2rem);
    height: 27.6rem;
  `}
`

export const PublicationCardTop = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2.4rem;
  height: 2rem;

  ${media.lessThan('medium')`
    flex-direction: column;
    height: 4.4rem;
  `}
`

export const PublicationCategory = styled(Link)`
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  line-height: 150%;
  color: var(--pink);
  font-weight: 700;
  font-size: var(--font12);
  max-width: 30rem;
  height: 2rem;
  cursor: pointer;
  text-decoration: none;

  ${media.lessThan('medium')`
    margin-bottom: .8rem;
  `}
`

export const PublicationDate = styled.p`
  font-size: var(--font12);
  line-height: 133%;
  font-weight: 400;
  color: ${alvTokens.ColorNeutralInverse00};
`

export const PublicationTitle = styled.div`
  display: flex;
  margin-bottom: 2.4rem;

  ${media.lessThan('medium')`
    margin-bottom: .8rem;
  `}
`

export const PublicationImg = styled(Img)`
  width: 8rem;
  height: 8rem;
  margin-right: 2.4rem;
  border-radius: 4px;

  ${media.lessThan('medium')`
    display: none;
  `}
`

export const PubTitleLink = styled(Link)`
  text-decoration: none;
`

export const PubTitle = styled.h3`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: ${props => props.theme.colors.brand.primary[10]};
  line-height: 133%;
  font-weight: 700;
  font-size: var(--font24);
  margin-bottom: .8rem;
  height: 6.4rem;
  
  ${media.lessThan('medium')`
    font-size: var(--font20);
    margin-bottom: 1.6rem;
    height: auto;
  `}
`

export const PubTitleWithImg = styled(PubTitle)`
  width: 38.3rem;
  height: 6.4rem;
  margin-bottom: 0;

  ${media.lessThan('medium')`
    height: auto;
  `}
`

export const PublicationDescription = styled(Link)`
  text-decoration: none;

  p {
    font-size: var(--font16);
    line-height: 150%;
    height: 12rem;
    font-weight: 400;
    color: ${alvTokens.ColorPaletteGray70};
    margin-bottom: 2.4rem;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;

    ${media.lessThan('medium')`
      font-size: var(--fontDefault);
      height: 8rem;
      -webkit-line-clamp: 4;
    `}
  }
`

export const PublicationDescriptionWithImg = styled(PublicationDescription)`
  p {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    height: 7.4rem;
  
    ${media.lessThan('medium')`
      -webkit-line-clamp: 4;
      height: 8.4rem;
    `}
  }
`

export const PublicationAuthor = styled.div`
  display: flex;
  align-items: center;
`

export const PublicationAuthorImgLink = styled(Link)``

export const PublicationAuthorImg = styled(Img)`
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  margin-right: 1.45rem;
`

export const PublicationAuthorNameLink = styled(Link)`
  text-decoration: none;
  color: var(--grayStrong);
`

export const PublicationAuthorName = styled.p`
  line-height: 115%;
  font-weight: 700;
  font-size: var(--fontDefault);
`

export const PaginationList = styled.ul`
  padding: 0 0 8rem;
  list-style: none;
  display: flex;
  margin: 6rem 0;
  justify-content: flex-start;
  align-items: center;
  margin: 0 auto;
  max-width: 1094px;
  height: 3.2rem;

  ${media.lessThan('medium')`
    margin: 0;
    padding: 0 0 3.2rem;
  `}
`

export const PaginationListItem = styled.li`
  display: inline-block;
  font-size: 12px;
  color: var(--white);
  letter-spacing: 1px;
`

export const PaginationItem = styled.span`
  font-size: 14px;
  color: var(--white);
  padding: .8rem 1.3rem;
  font-weight: 500;
  line-height: 115%;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
`

export const PaginationItemActive = styled(PaginationItem)`
  background-color: var(--white);
  color: ${props => props.theme.colors.brand.primary[10]};
  border-radius: 4px;
`

export const PaginationListItemLink = styled.div`
  width: 3.8rem;
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  color: var(--white);
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
`

export const PaginationIconLeft = styled(ChevronLeft)`
  font-size: 24px !important;
  color: var(--white);
  margin-left: 1rem;
  margin-right: 1rem;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
`

export const PaginationIconRight = styled(ChevronRight)`
  font-size: 24px !important;
  color: var(--white);
  margin-left: 1rem;
  margin-right: 1rem;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
`
