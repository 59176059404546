import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import BlogHome from '../components/blog/BlogHome'
import Layout from '../components/layout'
import SEO from '../components/seo'

const BlogPostList = ({ data, location, pageContext }) => {
  const {
    regularPosts: { edges: regularPosts },
  } = data

  const {
    highlightedPosts: { edges: highlightedPosts },
  } = data

  const {
    wordpressWpGatsbyPage: {
      acf: {
        title,
        subtitle,
        demonstrative_text,
        complementary_text,
      },
    },
  } = data

  const headerInfo = {
    title,
    subtitle,
    demonstrative_text,
    complementary_text
  }

  const wordpressData = data.wordpressWpGatsbyPage.acf

  return (
    <Layout location={location} pageContext={pageContext}>
      <SEO 
        title={wordpressData.title}
        description={wordpressData.subtitle}
        canonical={wordpressData.canonical}
        pathName={location.pathname}
      />
      <BlogHome
        headerInfo={headerInfo}
        data={data}
        highlightedPosts={highlightedPosts}
        regularPosts={regularPosts}
      />
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    regularPosts: allWordpressPost(
      filter: { sticky: { eq: false } }
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          title
          featured_media {
            localFile {
              childImageSharp {
                fluid(quality: 90, maxWidth: 432) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          slug
          date(locale: "pt-br", formatString: "DD [de] MMMM [de] YYYY")
          unformattedDate: date(formatString: "MM-DD-YYYY", locale: "pt-br")
          author {
            name
            wordpress_id
          }
          excerpt
          categories {
            name
          }
        }
      }
    }
    highlightedPosts: allWordpressPost(
      filter: { sticky: { eq: true }, status: { eq: "publish" } }
    ) {
      edges {
        node {
          title
          featured_media {
            localFile {
              childImageSharp {
                fluid(quality: 90, maxWidth: 432) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          slug
          date(locale: "pt-br", formatString: "DD [de] MMMM [de] YYYY")
          unformattedDate: date(formatString: "MM-DD-YYYY", locale: "pt-br")
          author {
            name
            wordpress_id
          }
          excerpt
          categories {
            name
          }
        }
      }
    }
    wordpressWpGatsbyPage(slug: { eq: $slug }) {
      acf {
        title
        subtitle
        demonstrative_text
        complementary_text
        canonical
        blog_all_categories_filter_title
        blog_back_button_title
        blog_no_results_title
        blog_search_title
      }
    }
  }
`

BlogPostList.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
}

export default BlogPostList
