import styled from 'styled-components'
import Img from 'gatsby-image'
import media from 'styled-media-query'

export const CardHeaderPlaceholder = styled.div`
  width: 100%;
  background: ${props => props.theme.colors.brand.primary[10]};
`

export const CardHeaderImg = styled(Img)`
  width: 50.3rem;
  height: 32rem;
  border-radius: 4px;

  ${media.lessThan('medium')`
    width: 100%;
    height: 17rem;
    max-width: calc(100vw - 3.2rem);
    margin: 0 auto;
  `}
`
