import styled from 'styled-components'
import media from 'styled-media-query'
import { ChevronLeft, ChevronRight } from '@dasa-health/alma-icons'

export const PaginationList = styled.ul`
  padding: 0 0 8rem;
  list-style: none;
  display: flex;
  margin: 6rem 0;
  justify-content: flex-start;
  align-items: center;
  margin: 0 auto;
  max-width: 1094px;
  height: 3.2rem;

  ${media.lessThan('medium')`
    margin: 0;
    padding: 0 0 3.2rem;
  `}
`

export const PaginationListItem = styled.li`
  display: inline-block;
  font-size: 12px;
  color: ${props => props.color ? props.theme.colors.brand.primary[10] : "var(--white)" };
  letter-spacing: 1px;
`

export const PaginationItem = styled.span`
  font-size: 14px;
  color: ${props => props.color ? props.theme.colors.brand.primary[10] : "var(--white)" };
  padding: .8rem 1.3rem;
  font-weight: 500;
  line-height: 115%;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
`

export const PaginationItemActive = styled(PaginationItem)`
  background-color: ${props => props.color ? props.theme.colors.brand.primary[10] : "var(--white)" };
  color: ${props => props.color ? "var(--white)" : props.theme.colors.brand.primary[10]};
  border-radius: 4px;
`

export const PaginationListItemLink = styled.div`
  width: 3.8rem;
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  color: var(--white);
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
`

export const PaginationIconLeft = styled(ChevronLeft)`
  font-size: 24px !important;
  color: ${props => props.color ? props.theme.colors.brand.primary[10] : "var(--white)"};
  margin-left: 1rem;
  margin-right: 1rem;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
`

export const PaginationIconRight = styled(ChevronRight)`
  font-size: 24px !important;
  color: ${props => props.color ? props.theme.colors.brand.primary[10] : "var(--white)"};
  margin-left: 1rem;
  margin-right: 1rem;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
`
