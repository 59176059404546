import styled from 'styled-components'
import media from 'styled-media-query'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'gatsby'
import { alvTokens, dasTokens } from '@dasa-health/alma-tokens'
import { ContainerDasa } from '../../shared/container'
import { Search, ArrowLeft } from "@dasa-health/alma-icons";
import { CarouselContainer } from '../../carousel/styled'

export const BlogHomeWrapper = styled(ContainerDasa)`
  max-width: 1094px;
`

export const BlogTitleAndSearch = styled.div`
  display: grid;
  grid-template-columns: 80.9rem 22.9rem;
  gap: 3.2rem 5.6rem;
  position: relative;
  margin-bottom: 4rem;
  grid-template-areas:
		"title search"
		"subtitle search"
  ;

  ${media.lessThan('medium')`
    gap: 0;
    grid-template-columns: 1fr;
    grid-template-areas:
		  "title"
		  "search"
		  "subtitle"
    ;
    margin-bottom: 2.4rem;
  `}
`

export const BlogTitle = styled.h1`
  grid-area: title;
  font-size: var(--fontTitle);
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -1px;
  color: ${dasTokens.ColorBrandBase01};
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;

  ${media.lessThan('medium')`
    font-size: var(--font20);
    letter-spacing: normal;
    margin-bottom: 1.6rem;
  `}
`

export const BlogSubtitle = styled.p`
  grid-area: subtitle;
  font-size: 14px;
  color: ${alvTokens.ColorPaletteGray30};
  line-height: 170%;
  font-weight: 400;
  max-width: 75.9rem;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  
  ${media.lessThan('medium')`
    max-width: 100%;
    color: ${alvTokens.ColorPaletteGray70};
  `}
`

export const HighlightPosts = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2.4rem;
`

export const RegularPosts = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2.4rem;
  margin-bottom: 3.2rem;
`

export const FilteredSearch = styled(RegularPosts)``

export const PaginationList = styled.ul`
  padding: 0;
  list-style: none;
  display: flex;
  margin: 6rem 0;
  justify-content: center;
  align-items: center;

  ${media.lessThan('medium')`
    margin: 5rem 0;
  `}
`
export const PaginationListItem = styled.li`
  display: inline-block;
  font-size: 12px;
  color: #7D7D7D;
  letter-spacing: 1px;
`
export const PaginationListItemLink = styled(Link)`
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  color: var(--pink);
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
`

export const PaginationIcon = styled(FontAwesomeIcon)`
  font-size: var(--font24);
  margin-left: 1rem;
  margin-right: 1rem;
`

export const TitleRecentPosts = styled.h2`
  font-size: var(--font24);
  color: var(--blue);  
  font-weight: 700;
  line-height: 120%;
  margin: 4.8rem 0 3.2rem;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;

  ${media.lessThan('medium')`
    font-size: var(--font20);
  `}
`

export const CategoryContent = styled.div`
  display: ${props => !props.search ? 'flex' : 'none'};
  align-items: center;
  margin-bottom: 4rem;
  position: relative;
  justify-content: space-between;

  ${media.lessThan('medium')`
    display: none;
  `}
`

export const CategoryContentMobile = styled.div`
  display: none;

  ${media.lessThan('medium')`
    display: ${props => props.hidden ? 'flex' : 'none'};
    justify-content: space-between;
    flex-direction: column-reverse;
    position: relative;
    align-items: flex-start;
    margin-bottom: 2.4rem;
  `}
`

export const ResultSearchContent = styled.div`
  display: none;
  align-items: center;
  margin-bottom: 4rem;
  position: relative;
  justify-content: space-between;

  ${media.lessThan('medium')`
    flex-direction: column-reverse;
    align-items: flex-start;
    margin-bottom: 2.4rem;
  `}

  &.visible {
    display: flex;
  }
`

export const SearchContentMobile = styled.div`
  display: none;

  ${media.lessThan('medium')`
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    margin-bottom: 2.4rem;
  `}
`

export const ArrowBack = styled(ArrowLeft)`
  width: 2.4rem;
  height: 2.4rem;
  margin-right: 2.4rem;
  cursor: pointer;
`

export const TitleSearchWrapper = styled.h3`
  grid-area: search;
  display: flex;
  align-items: center;
  position: relative;

  ${media.lessThan('medium')`
    align-items: flex-start;
  `}
`

export const TitleSearch = styled.h3`
  font-size: var(--font24);
  font-weight: 700;
  line-height: 120%;
  color: ${dasTokens.ColorBrandBase01};

  span {
    color: var(--pink);
  }

  ${media.lessThan('medium')`
    font-size: var(--font20);
  `}
`

export const SearchWrapper = styled.div`
  display: flex;
  justify-content: flex-end;  

  ${media.lessThan('medium')`
    width: 100%;
    margin-bottom: 2.4rem;
  `}
`

export const SearchIconLink = styled(Link)`
  text-decoration: none;
  color: ${dasTokens.ColorBrandBase01};
`

export const SearchIcon = styled(Search)`
  width: 2.4rem;
  height: 2.4rem;
  position: absolute;
  box-sizing: border-box;
  top: 50%;
  right: 19.2rem;
  transform: translateY(-50%);
  color: ${dasTokens.ColorBrandBase01};

  ${media.lessThan('medium')`
    left: 1.2rem;
  `}
`
export const SearchInput = styled.input` 
  width: 22.9rem;
  height: 4.8rem;
  box-sizing: border-box;
  padding-left: 4.6rem;
  border-radius: 40px;
  background: var(--gray);
  color: ${dasTokens.ColorPaletteGray30};
  font-size: var(--font16);
  line-height: 150%;

  ${media.lessThan('medium')`
    width: calc(100vw - 3.2rem);
    height: 3.3rem;
  `}
`

export const SearchForm = styled.form`
  position: relative;
  height: 4.8rem;

  ${media.lessThan('medium')`
    height: 3.3rem;
  `}
`

export const SearchResults = styled.div`
  display: flex;
  flex-direction: column;
  height: 39.8rem;
  position: absolute;
  top: 6.4rem;
  z-index: 2;
  filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.1));

  ${media.lessThan('medium')`
    width: calc(100vw - 3.2rem);
    top: 8.2rem;
  `}
`

export const ResultItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: .8rem;
  width: 33.4rem;
  background: ${dasTokens.ColorPaletteWhite};
  padding: 1.6rem;

  ${media.lessThan('medium')`
    width: calc(100vw - 6.4rem);
  `}
`

export const ResultCategory = styled.p`
  text-transform: uppercase;
  color: var(--pink);
  line-height: 133%;
  letter-spacing: 1.5px;
  font-size: var(--font12);
  font-weight: 700;

  ${media.lessThan('medium')`
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  `}
`

export const ResultTitleLink = styled(Link)`
  text-decoration: none;
  border-bottom: 1px solid #bdbdbd;

  &:last-child {
    border-bottom: none;
  }
`

export const ResultTitle = styled.h3`
  font-size: var(--font16);
  font-weight: 500;
  line-height: 150%;
  color: ${alvTokens.ColorPaletteGray30};
  min-height: 4.8rem;
  cursor: pointer;

  ${media.lessThan('medium')`
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    min-height: 7.2rem;
  `}

`
export const ResultDate = styled.p`
  display: flex;
  align-items: center;
  height: 2rem;
  font-weight: 400;
  font-size: var(--font12);
  line-height: 133%;
  color: var(--grayStrong);
`

export const NoResultsContent = styled.div`
  margin-bottom: 8rem;
`

export const NoResults = styled.p`
  font-weight: 400;
  font-size: var(--fontDefault);
  line-height: 170%;
  color: ${dasTokens.ColorPaletteGray30};
  margin-bottom: 1.6rem;
`

export const LinkButton = styled.a`
  text-decoration: none;

  p {
    font-size: var(--font16);
  }
`

export const LinkBack = styled.a``

export const ListCategories = styled.div`
  display: ${props => !props.search ? 'flex' : 'none'};
  flex-wrap: wrap;
  width: 100%;
  gap: 1.6rem .8rem;
  position: relative;
  
  ${media.lessThan('medium')`
    display: none;
  `}
`

export const Category = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.6rem;
  padding: 1.2rem 1.6rem;
  background: none;
  color:  ${dasTokens.ColorNeutralOnBrand00};
  font-weight: 700;
  line-height: 150%;
  font-size: var(--font16);
  cursor: pointer;
  height: 4.8rem;

  &.active {
    background: rgba(0, 41, 189, 0.24);
    border-radius: 4px;
    color: ${dasTokens.ColorNeutralOnBrand20};
    cursor: default;

    svg {
      cursor: pointer;
    }
  }

  &.all {
    min-width: 18.5rem;

    ${media.lessThan('medium')`
      min-width: 14.8rem;
    `}
  }

  ${media.lessThan('medium')`
    height: 3.2rem;
    font-size: var(--fontDefault);
    line-height: 115%;
    padding: .8rem;
    justify-content: flex-start;
  `}
`

export const CarouselCategoriesContainer = styled(CarouselContainer)`
  display: none;

  ${media.lessThan('medium')`
    display: flex;
  `}

  .swiper-container {
    min-height: 3.2rem;
    margin-left: 0;

    .swiper-wrapper {
      gap: .8rem;
    }

    .swiper-slide {
      display: flex;
      justify-content: center;
      max-height: 1.6rem;
      margin: 0 !important;
      max-width: fit-content;
      justify-content: flex-center;

      &:first-child {
        width: 14.8rem !important;
      }

      &:last-child {
        margin-right: 0 !important;
      }
    }
  }
` 

export const ListCategoriesMobile = styled.div`
  display: none;

  ${media.lessThan('medium')`
    display: flex;
  `}
`
