import React from 'react'
import PropTypes from 'prop-types'
import * as S from './styled'
import StyledBlogCardImage from '../BlogCardImage'
import { listCategories } from '../../../utils/listCategories'
import { removeHTML } from '@cms/utils'
import DoctorLink from '../../../utils/getDoctorLink'
import getSlugByCountry from '../../../utils/getSlugByCountry'

const BlogCard = ({
  post: {
    title,
    author: { name, wordpress_id },
    date,
    excerpt,
    slug,
    featured_media: featuredMedia,
    categories
  },
  authorList,
  authorDasa,
  logoDasa,
  setCategories
}) => {
  const authors = []
  const blogPageSlug = getSlugByCountry('blog')

  if(authorList) {
    authors[authorDasa.wordpress_id] = {
      name: authorDasa.name
    }

    authorList.map((doctor) => {
      if(authors[doctor.acf.usuario]) return null
      return authors[doctor.acf.usuario] = ({
        name: doctor.acf.name,
        image: doctor.acf.photo_medic,
        titleGender: doctor.acf.title_gender,
        slug: doctor.slug
      })
    })
  }

  const filtePostsByCategory = (category) => {
    const categorySelected = category.toLowerCase()
    const categoryFormatted = categorySelected[0].toUpperCase() + categorySelected.substring(1)
    setCategories([categoryFormatted])

    if(typeof window !== `undefined`) {
      window.scrollTo({
          top: 0,
          left: 200,
          behavior: 'smooth'
      });
    }
  }

  return (
    <S.BlogCardWrapper img={featuredMedia}>
      <S.BlogCardLink to={`/${blogPageSlug}/${slug}/`}>
        <StyledBlogCardImage image={featuredMedia} />
      </S.BlogCardLink>

      <S.BlogCardTop>
        <S.BlogCardCategories
          onClick={e => filtePostsByCategory(e.target.innerText)}
          setCategories={setCategories}
        >
          {listCategories(categories)}
        </S.BlogCardCategories>

        <S.BlogCardDate>{date}</S.BlogCardDate>
      </S.BlogCardTop>

      <S.BlogCardLink to={`/${blogPageSlug}/${slug}/`}>
        <S.BlogCardTitle>{removeHTML(title)}</S.BlogCardTitle>
      </S.BlogCardLink>
        
      <S.BlogPostLink to={`/${blogPageSlug}/${slug}/`}>
        <S.BlogPostContent>
          <p>{removeHTML(excerpt)}</p>
        </S.BlogPostContent>
      </S.BlogPostLink>

      <S.BlogAuthor>
        {typeof(authors[wordpress_id]) !== "undefined" && wordpress_id !== 1
        ?
          <>
              <S.BlogAuthorLink
                to={DoctorLink(
                  {
                    title: authors[wordpress_id].titleGender,
                    name: authors[wordpress_id].name, 
                  })}
              >
                <S.BlogImage
                  fluid={authors[wordpress_id].image.localFile.childImageSharp.fluid} 
                  alt={authors[wordpress_id].name}
                />
              </S.BlogAuthorLink>

              <S.BlogAuthorLink
                to={DoctorLink(
                  {
                    title: authors[wordpress_id].titleGender, 
                    name: authors[wordpress_id].name,
                  })}
              >
                <S.BlogAuthorName>
                  {`${authors[wordpress_id].titleGender} ${authors[wordpress_id].name}`}
                </S.BlogAuthorName>
              </S.BlogAuthorLink>
            </>
        :
          <>
            <S.BlogImage
              fluid={logoDasa.url.localFile.childImageSharp.fluid}
              alt={name || authors[3].name}
            />

            <S.BlogAuthorName>
              {name || authors[3].name}
            </S.BlogAuthorName>
          </>
        }
      </S.BlogAuthor>
    </S.BlogCardWrapper>
  )
}

BlogCard.propTypes = {
  post: PropTypes.shape({
    title: PropTypes.string,
    author: PropTypes.shape({
      name: PropTypes.string,
    }),
    date: PropTypes.string,
    excerpt: PropTypes.string,
    slug: PropTypes.string,
    featured_media: PropTypes.object,
  }).isRequired,
}

export default BlogCard
