import React from 'react'
import * as S from './styled'

const BlogCardImage = ({ className, image }) => {
  if (!image?.localFile?.childImageSharp?.fluid) {
    return <S.CardHeaderPlaceholder />
  }

  return (
    <S.CardHeaderImg
      className={className}
      fluid={image?.localFile?.childImageSharp?.fluid}
    />
  )
}

export default BlogCardImage
