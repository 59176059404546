import React from 'react'
import PropTypes from 'prop-types'
import * as S from './styled'
import { listCategories } from '../../../utils/listCategories'
import { removeHTML } from '@cms/utils'
import DoctorLink from '../../../utils/getDoctorLink'
import getSlugByCountry from '../../../utils/getSlugByCountry'

const BlogHomeCardThin = ({
  post: {
    title,
    author: { name, wordpress_id },
    date,
    excerpt,
    slug,
    featured_media: featuredMedia,
    categories
  },
  authorList,
  authorDasa,
  logoDasa,
  setCategories
}) => {
  const authors = []
  const blogPageSlug = getSlugByCountry('blog')

  if(authorList) {
    authors[authorDasa.wordpress_id] = {
      name: authorDasa.name
    }

    authorList.map((doctor) => {
      if(authors[doctor.acf.usuario]) return null

      return authors[doctor.acf.usuario] = ({
        name: doctor.acf.name,
        image: doctor.acf.photo_medic,
        titleGender: doctor.acf.title_gender,
        slug: doctor.slug
      })
    })
  }

  const filtePostsByCategory = (category) => {
    const categorySelected = category.toLowerCase()
    const categoryFormatted = categorySelected[0].toUpperCase() + categorySelected.substring(1)
    setCategories([categoryFormatted])

    if(typeof window !== `undefined`) {
      window.scrollTo({
          top: 0,
          left: 200,
          behavior: 'smooth'
      });
    }
  }

  return (
    <S.BlogHomeCardThinWrapper>
        <S.CardTop>
          <S.CardCategories
            onClick={e => setCategories ? filtePostsByCategory(e.target.innerText) : ''}
            setCategories={setCategories}
          >
            {listCategories(categories)}
          </S.CardCategories>

          <S.CardDate>{date}</S.CardDate>
        </S.CardTop>

        {featuredMedia !== null && featuredMedia.localFile
        ?
          <>
            <S.CardLink
              to={`/${blogPageSlug}/${slug}/`}
            >
              <S.CardWithImg>
                <S.CardImg
                  fluid={featuredMedia.localFile.childImageSharp.fluid} 
                  alt={title}
                />

                <S.CardTitleWithImg>
                  {title}
                </S.CardTitleWithImg>
              </S.CardWithImg>
            </S.CardLink>

            <S.CardLink
              to={`/${blogPageSlug}/${slug}/`}
            >
              <S.CardContentWithImg>
                <p>{removeHTML(excerpt)}</p>
              </S.CardContentWithImg>
            </S.CardLink>
          </>
        :
          <>
            <S.CardLink
              to={`/${blogPageSlug}/${slug}/`}
            >
              <S.CardTitle>{title}</S.CardTitle>
            </S.CardLink>

            <S.CardLink
              to={`/${blogPageSlug}/${slug}/`}
            >
              <S.CardContent>
                <p>{removeHTML(excerpt)}</p>
              </S.CardContent>
            </S.CardLink>
          </>
        }
        
        <S.CardAuthor>
        {typeof(authors[wordpress_id]) !== "undefined" && wordpress_id !== 1
        ?
          <>
            <S.CardLink
              to={DoctorLink(
                {
                  title: authors[wordpress_id].titleGender, 
                  name: authors[wordpress_id].name,
                })}
            >
              <S.CardAuthorImg
                fluid={authors[wordpress_id].image.localFile.childImageSharp.fluid}
                alt={authors[wordpress_id].name}
              />
            </S.CardLink>

            <S.CardLink
              to={DoctorLink(
                {
                  title: authors[wordpress_id].titleGender, 
                  name: authors[wordpress_id].name
                 })}
            >
              <S.CardAuthorName>
                {`${authors[wordpress_id].titleGender} ${authors[wordpress_id].name}`}
              </S.CardAuthorName>
            </S.CardLink>
          </>
        :
          <>
            <S.CardAuthorImg
              fluid={logoDasa.url.localFile.childImageSharp.fluid}
              alt={name || authors[3].name}
            />

            <S.CardAuthorName>
              {name || authors[3].name}
            </S.CardAuthorName>
          </>
        }
        </S.CardAuthor>
    </S.BlogHomeCardThinWrapper>
  ) 
}

BlogHomeCardThin.propTypes = {
  post: PropTypes.object.isRequired,
}

export default BlogHomeCardThin
