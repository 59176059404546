import styled from 'styled-components'
import { alvTokens } from '@dasa-health/alma-tokens'
import media from 'styled-media-query'
import { 
  PublicationDescription,
  PublicationDescriptionWithImg,
  PublicationImg,
  PublicationTitle,
  PubTitleWithImg
} from '../../single-clinical-staff/styled'

import {
  BlogAuthor,
  BlogImage,
  BlogAuthorName,
  BlogCardCategories, 
  BlogCardLink, 
  BlogCardTitle, 
  BlogCardTop, 
  BlogCardWrapper
} from '../BlogCard/styled'

export const BlogHomeCardThinWrapper = styled(BlogCardWrapper)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--gray);
  height: 32rem;

  ${media.lessThan('medium')`
    width: 100%;
    max-width: calc(100vw - 3.2rem);
    height: auto;
  `}
`

export const CardTop = styled(BlogCardTop)`
  margin: 0 0 2.4rem;
`
export const CardCategories = styled(BlogCardCategories)``
export const CardLink = styled(BlogCardLink)``
export const CardAuthor = styled(BlogAuthor)``
export const CardAuthorImg = styled(BlogImage)``
export const CardAuthorName = styled(BlogAuthorName)`
  color: var(--grayStrong);
`

export const CardContent = styled(PublicationDescription)`
  p {
    -webkit-line-clamp: 4;
    height: auto;
  }
`
export const CardContentWithImg = styled(PublicationDescriptionWithImg)``
export const CardWithImg = styled(PublicationTitle)`
  ${media.lessThan('medium')`
    margin-bottom: 1.6rem;
  `}
`
export const CardTitleWithImg = styled(PubTitleWithImg)`
  ${media.lessThan('medium')`
    width: 100%;
  `}
`
export const CardTitle = styled(BlogCardTitle)`
  min-height: 6.4rem;
`
export const CardImg = styled(PublicationImg)`
  ${media.lessThan('medium')`
    display: none;
  `}
`

export const CardDate = styled.p`
  font-size: 12px;
  color: var(--grayStrong);
  line-height: 133%;
  background: ${alvTokens.ColorPaletteGray160};
  padding: .2rem .4rem;
  border-radius: 4px;

  ${media.lessThan('medium')`
    background: none;
    padding: 0;
  `}
`

export const IconHolder = styled.span`
  color: var(--pink);
  font-size: 24px;
  padding: 2rem 0.5rem;
`
