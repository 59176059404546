export const listCategories = (data) => {
    var categories = ""

    if(data.length === 0) return categories;

    data.map((category, index) => {
      if(index !== 0) {
        return categories += `, ${category.name}`
      } else {
        return categories += category.name
      }
    })

    return categories
}