import { replaceSpecialChars } from '@cms/utils'
import getSlugByCountry from '../utils/getSlugByCountry'

const DoctorLink = ({ title, name }) => {
  const pageSlug = getSlugByCountry('corpoClinico')
  const doctorLink = `/${pageSlug}/${replaceSpecialChars(title + name)}/`;

  return doctorLink;
}

export default DoctorLink;
